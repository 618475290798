export namespace AttributeEnums {
    export enum Countdown {
        Countdown = 'data-countdown',
        CountdownSeconds = 'data-countdown-seconds',
        CountdownMinutes = 'data-countdown-minutes',
        CountdownHours = 'data-countdown-hours',
        CountdownDays = 'data-countdown-days',
        CountdownEnds = 'data-countdown-ends',
        CountdownExpires = 'data-countdown-expires'
    }

    export enum DropDowns {
        ProductDropDown = 'data-product-drop-down',
        ProductDisplayText = 'data-product-display-text',
        ProductDisplayName = 'data-product-display-name',
        ProductDisplaySwatch = 'data-product-display-swatch',
        SelectedProductUrl = 'data-selected-product-url'
    }

    export enum Product {
        Product = 'data-product-parent',
        ProductUrl = 'data-product-url',
        ProductSizeId = 'data-product-size-id',
        ProductGroupId = 'data-product-group-id',
        ProductLineId = 'data-product-line-id',
        ProductPrice = 'data-product-price',
        ProductPriceSummary = 'data-product-price-summary',
        ProductAdditionPrice = 'data-product-addition-price',
        ProductScrollIntoView = 'data-product-scroll-into-view',
        ProductOptionsPanel = 'data-product-options-panel',
        ProductAddToCart = 'data-product-add-to-cart',
        ProductAddToCartAttempted = 'data-product-add-to-cart-attempted',
        ProductSelectionPanel = 'data-product-selection-panel'
    }

    export enum ProductFinancePanel {
        ProductFinancePanel = 'data-product-finance-panel'
    }

    export enum ProductPrice {
        ProductRrpPrice = 'data-product-rrp-price',
        ProductWasPrice = 'data-product-was-price',
        ProductNowPrice = 'data-product-now-price',
        ProductSavePrice = 'data-product-save-price',
        ProductBaseRrpPrice = 'data-product-base-rrp-price',
        ProductBaseWasPrice = 'data-product-base-was-price',
        ProductBaseNowPrice = 'data-product-base-now-price',
        ProductBaseSavePrice = 'data-product-base-save-price',
    }

    export enum ProductDelivery {
        ProductDeliveryPanel = 'data-product-delivery-panel',
        ProductDeliveryPostcode = 'data-product-delivery-postcode',
        ProductDeliveryPostcodeChange = 'data-product-delivery-postcode-change',
        ProductDeliveryPanelLineId = 'data-product-delivery-panel-line-id'
    }

    export enum ProductSelector {
        ProductSelector = 'data-product-selector',
        ProductSelectorItem = 'data-product-selector-item',
        ProductSelectorGroupName = 'data-product-selector-group-name',
        ProductSelectorGroupId = 'data-product-selector-group-id',
        ProductSelectorSizeId = 'data-product-selector-size-id',
        ProductSelectorExtraId = 'data-product-selector-extra-id',
        ProductSelectorName = 'data-product-selector-name',
        ProductSelectorSwatchSrc = 'data-product-selector-swatch-src',
        ProductSelectorSwatchPreviewSrc = 'data-product-selector-swatch-preview-src',
        ProductSelectorPrice = 'data-product-selector-price',
        ProductSelectorType = 'data-product-selector-type',
        ProductSelectorFor = 'data-product-selector-for',
        ProductSelectorIsSelected = 'data-product-selector-is-selected',
        ProductSelectorFullsizeUrl = 'data-product-selector-full-size-url'
    }

    export enum ColourPicker {
        ColourPicker = 'data-colour-picker',
        ColourPickerGroupId = 'data-colour-picker-group-id',
        ColourPickerValue = 'data-colour-picker-value',
        ColourPickerName = 'data-colour-picker-name',
        ColourPickerSwatchSrc = 'data-colour-picker-swatch-src',
        ColourPickerAdditionPrice = 'data-colour-picker-addition-price',
        ColourPickerLineId = 'data-colour-picker-line-id',
        ColourPickerFor = 'data-colour-picker-for',
        ColourPickerGallery = 'data-colour-picker-gallery',
        ConfirmColourFor = 'data-confirm-colour-for',
        ColourPickerDropDown = 'data-colour-picker-drop-down'
    }
    export enum Gallery {
        Gallery = 'data-gallery',
        GalleryId = 'data-gallery-id',
        GallerySelectableCell = 'data-gallery-selectableCell'
    }

    export enum GroupPicker {
        GroupPicker = 'data-group-picker'
    }

    export enum SizePicker {
        ProductSizePicker = 'data-product-size-picker',
        ProductSizePickerHandheld = 'data-product-size-picker-handheld'
    }

    export enum ProductGtm {
        GtmProduct = 'data-gtm-product',
        GtmProductId = 'data-gtm-product-id',
        GtmProductGroupSku = 'data-gtm-product-group-sku',
        GtmProductSku = 'data-gtm-product-sku',
        GtmProductName = 'data-gtm-product-name',
        GtmProductPrice = 'data-gtm-product-price',
        GtmProductBrand = 'data-gtm-product-brand',
        GtmProductSize = 'data-gtm-product-size',
        GtmProductCategory = 'data-gtm-product-category'
    }

    export enum PopOut {
        PopOutId = 'data-pop-out-id',
        PopOutFamily = 'data-pop-out-family',
        PopOutWrapper = 'data-pop-out-wrapper',
        PopOutBacklink = 'data-pop-out-backlink',
        PopOutIdOpen = 'data-pop-out-id-open',
        PopOutFamilyOpen = 'data-pop-out-family-open',
        PopOutIdCloseAll = 'data-pop-out-id-close-all',
        PopOutOverlayClickIsBack = 'data-pop-out-overlay-click-is-back'
    }

    export enum BasketPopOut {
        BasketPopOut = 'data-basket-pop-out',
        BasketPopOutWrapper = 'data-pop-out-wrapper',
        BasketPopOutProduct = 'data-basket-pop-out-product',
        BasketPopOutSummary = 'data-basket-pop-out-summary',
        BasketPopOutItemSummary = 'data-basket-pop-out-item-summary',
        BasketPopOutRecommendations = 'data-basket-pop-out-recommendations'
    }

    export enum Carousel {
        LineId = 'data-line-id',
        Tile = 'data-tile',
        Carousel = 'data-carousel',
        CarouselTileAddButton = 'data-carousel-tile-add-button',
        Container = 'data-carousel-container',
        Cells = 'data-carousel-cell',
        CellsPerPage = 'data-carousel-cells-per-page',
    }

    export enum CarouselGtm {
        GtmProductCarousel = 'data-gtm-product-carousel',
        GtmProductCarouselId = 'data-gtm-product-carousel-id',
        GtmProductCarouselGroupSku = 'data-gtm-product-carousel-group-sku',
        GtmProductCarouselSku = 'data-gtm-product-carousel-sku',
        GtmProductCarouselName = 'data-gtm-product-carousel-name',
        GtmProductCarouselPrice = 'data-gtm-product-carousel-price',
        GtmProductCarouselBrand = 'data-gtm-product-carousel-brand',
        GtmProductCarouselSize = 'data-gtm-product-carousel-size',
        GtmProductCarouselCategory = 'data-gtm-product-carousel-category',
        GtmProductCarouselUrl = 'data-gtm-product-carousel-url'
    }

    export enum CarouselInfiniteLoad {
        CarouselInfiniteLoad = 'data-carousel-infinite-load',
        Url = 'data-url',
        LastTile = 'data-last-tile',
        GetCellsOnInitialise = 'data-get-cells-on-initialise'
    }
    export enum CarouselTileLoad {
        CarouselTileLoad = 'data-carousel-tile-load',
        Url = 'data-carousel-tile-load-url'
    }

    export enum ReadMore {
        ReadMoreButting = 'data-read-more-butting',
        ReadMoreButtingOffset = 'data-read-more-butting-offset',
        ReadMoreToggle = 'data-read-more-toggle',
        ReadMoreReturnPosition = 'data-read-more-return-position',
        ReadMoreHeight = 'data-read-more-height',
        ReadMoreContent = 'data-read-more-content'
    }
    export enum ShowHide {
        ShowHideToggle = 'data-show-hide-toggle',
        ShowHideContent = 'data-show-hide-content',
        ShowToggleText = 'data-show-hide-show',
        HideToggleText = 'data-show-hide-hide'
    }

    export enum Forms {
        Checkbox = 'data-checkBox',
        CheckboxElementToggle = 'data-checkBoxElementToggle',
        RadioGroup = 'data-radioGroup',
        RadioToggle = 'data-radioToggle'
    }

    export enum ZoomableGallery {
        ZoomableGallery = 'data-zoomable-gallery',
        ZoomableGalleryPopOutPlaceholder = 'data-zoomable-gallery-pop-out-placeholder'

    }
    export enum CarouselGallery {
        CarouselGallery = 'data-carousel-gallery',
        ComponentOrientation = 'data-carousel-gallery-component-orientation',
        SingleCarouselComponentOrientation = 'data-carousel-gallery-single-carousel-component-orientation'
    }

    export enum PagingCarouselGallery {
        PagingCarouselGallery = 'data-paging-carousel-gallery',
        CarouselSliderElement = 'data-carousel-slider',
        MainDisplayPrevElement = 'data-main-display-prev',
        MainDisplayNextElement = 'data-main-display-next',
        CarouselPrevElement = 'data-carousel-prev',
        CarouselNextElement = 'data-carousel-next'
    }

    export enum ProductListingPage {
        ProductListingPage = 'data-product-listing-page',
        ProductListingPageShowNoProductsDialogue = 'data-product-listing-page-show-no-products-dialogue'
    }

    export enum ProductListingPageTiles {
        ProductListingPageTiles = 'data-product-listing-page-tiles'   
    }

    export enum ProductListingPageTile {
        ProductListingPageTile = 'data-product-listing-page-tile',
        ProductListingPageTileLineId = 'data-product-listing-page-tile-line-id',
        ProductListingPageTileUrl = 'data-product-listing-page-tile-url',
        ProductListingPageTileName = 'data-product-listing-page-tile-name',
        ProductListingPageTilePrice = 'data-product-listing-page-tile-price',
        ProductListingPageTileBrand = 'data-product-listing-page-tile-brand',
        ProductListingPageTileSize = 'data-product-listing-page-tile-size',
        ProductListingPageTileCategory = 'data-product-listing-page-tile-category'
    }

    export enum ProductListingPageHeader {
        ProductListingPageHeader = 'data-product-listing-page-header',
        ProductListingPageFilterBarResults = 'data-product-listing-page-filter-bar-results',
        ProductListingPageSort = 'data-product-listing-page-sort',
        ProductListingPageFilterBarResultsStartProduct = 'data-product-listing-page-filter-bar-results-start-product',
        ProductListingPageFilterBarResultsProductCount = 'data-product-listing-page-filter-bar-results-product-count',
        ProductListingPageFilterBarResultsTotalProducts = 'data-product-listing-page-filter-bar-results-total-products',
        ProductListingPageHeaderHeading = 'data-product-listing-page-header-heading',
        ProductListingPageHeaderQuickLinks = 'data-product-listing-page-header-quick-links',
        ProductListingPageHeaderQuickLink = 'data-product-listing-page-header-quick-link',
        ProductListingPageHeaderQuickLinkName = 'data-product-listing-page-header-quick-link-name',
        ProductListingPageHeaderReadMoreValue = 'plp-header-intro-1'
    }
    export enum ProductListingPageDescription {
        ProductListingPageDescription = 'data-product-listing-page-description'
    }
    export enum ProductListingPageQuestionsAnswers {
        ProductListingPageQuestionsAnswers = 'data-product-listing-page-questions-answers'
    }
    export enum BreadcrumbHiddenField {
        BreadcrumbHiddenField = 'data-breadcrumb-hidden-field',
        BreadcrumbHiddenFieldUrl = 'data-breadcrumb-hidden-field-url',
        BreadcrumbHiddenFieldName = 'data-breadcrumb-hidden-field-name',
        BreadcrumbHiddenFieldPosition = 'data-breadcrumb-hidden-field-position'
    }

    export enum ProductListingPageOptions {
        ProductListingPageOptions = 'data-product-listing-page-options',
        ProductTotal = 'data-view-products-button-total-products',
        ProductListingPageOptionsPopOutValue = 'product-listing-page-options-pop-out'
    }

    export enum ProductListingPageGroupOptions {
        ProductListingPageGroupOptions = 'data-product-listing-page-group-options',
    }

    export enum ProductListingPageShowMore {
        ProductListingPageShowMore = 'data-product-listing-page-show-more',
        ProductListingPageShowMoreButton = 'data-product-listing-page-show-more-button',
        ProductListingPageShowingStartProduct = 'data-product-listing-page-showing-start-product',
        ProductListingPageShowingProductCount = 'data-product-listing-page-showing-product-count',
        ProductListingPageShowingTotalProducts = 'data-product-listing-page-total-products',

    }

    export enum ProductListingPageOption {
        ProductListingPageOption = 'data-product-listing-page-option',
        ProductListingPageOptionSelected = 'data-product-listing-page-option-selected',
        ProductListingPageOptionTitle = 'data-product-listing-page-option-title'

    }

    export enum ProductListingPageSelectedOptionsCarousel {
        ProductListingPageSelectedOptionsCarousel = 'data-product-listing-page-selected-options-carousel'
    }

    export enum BannerPromotion {
        BannerPromotion = 'data-banner-promotion',
        BannerPromotionName = 'data-banner-promotion-name'
    }

    export enum Breadcrumb {
        Breadcrumb = "data-breadcrumb",
        BreadcrumbContainer = "data-breadcrumb-container",
    }

    export enum CategoryPagingSelectableCarousel {
        CategoryPagingSelectableCarousel = 'data-category-paging-selectable-carousel',
        CategoryPagingSelectableCarouselValue = 'data-category-paging-selectable-carousel-value'
    }

    export enum ButtonCarousel {
        ButtonCarousel = 'data-button-carousel'

    }
    export enum ButtonCarouselButton {
        ButtonCarouselButton = 'data-button-carousel-button',
        ButtonCarouselButtonValue = 'data-button-carousel-button-value'
    }

    export enum PagingCarouselGalleryWithZoomButton {
        PagingCarouselGalleryWithZoomButton = 'data-paging-carousel-gallery-with-zoom-button',
        ZoomButton = 'data-paging-carousel-gallery-with-zoom-button-button'
    }

    export enum PopOutCarouselGallery {
        PopOutCarouselGallery = 'data-pop-out-carousel-gallery',
        Close = 'data-pop-out-carousel-gallery-close',
        singlePagingHorizontal = 'lb_popUpCarouselGallery-singleHorizontal',
        pagingSelectableHorizontal = 'lb_popUpCarouselGallery-pagingHorizontal',
        singlePagingVertical = 'lb_popUpCarouselGallery-singleVertical',
        pagingSelectableVertical = 'lb_popUpCarouselGallery-pagingVertical'
    }

    export enum SelectableCarousel {
        SelectableCarousel = 'data-selectable-carousel',
        Slider = 'data-selectable-carousel-slider',
        Cell = 'data-selectable-carousel-cell',
        FillerCell = 'data-selectable-carousel-filler-cell',
        SelectedCell = 'data-selectable-carousel-cell-selected',
        ComponentOrientation = 'data-selectable-carousel-component-orientation',
        InitialSelection = 'data-selectable-carousel-component-initial-selection'

    }

    export enum PagingSelectableCarousel {
        PagingSelectableCarousel = 'data-paging-selectable-carousel',
        Slider = 'data-selectable-carousel-slider',
        Prev = 'data-paging-selectable-carousel-prev',
        Next = 'data-paging-selectable-carousel-next',
        Cell = 'data-selectable-carousel-cell'
    }

    export enum SinglePagingSelectableCarousel {
        SinglePagingSelectableCarousel = 'data-single-paging-selectable-carousel'
    }

    export enum InstanceComponentInitialiser {
        Ignore = 'data-initialiser-ignore'
    }

    export enum BaseMediaTile {
        MediaTile = "data-media-tile"
    }

    export enum ImageMediaTile {
        ImageMediaTile = "data-image-media-tile"
    }

    export enum ButtonImageMediaTile {
        ButtonImageMediaTile = "data-button-image-media-tile"
    }

    export enum ZoomedImageMediaTile {
        ZoomedImageMediaTile = "data-zoomed-image-media-tile"
    }

    export enum YouTubeMediaTile {
        YouTubeMediaTile = "data-you-tube-media-tile",
        DisplayControl = "data-you-tube-media-tile-display-control",
        DisableKeyboardControls = "data-you-tube-media-tile-disable-keyboard-controls",
        AutoPlay = "data-you-tube-media-tile-auto-play",
        ClosedCaptionOnLoad = "data-you-tube-media-tile-closed-caption-on-load",
        DisableFullScreen = "data-you-tube-media-tile-disable-full-screen"
    }
    export enum LazyYouTubeMediaTile {
        LazyYouTubeMediaTile = "data-lazy-you-tube-media-tile"
    }

    export enum SwatchImage {
        SwatchImage = 'data-swatch-image',
        IsHidden = 'data-swatch-image-is-hidden',
        GallerySwatchImage = 'data-gallery-swatch-image'
    }

    export enum Basket {
        Basket = 'data-basket',
        BasketRecommendations = 'data-basket-recommendations',
        BasketSummary = 'data-basket-summary',
        BasketCheckoutButton = "data-basket-checkout-button"
    }

    export enum BasketLine {
        BasketLine = 'data-basket-line',
        Assembly = 'data-basket-line-assembly',
        Removal = 'data-basket-line-removal',
        Quantity = 'data-basket-line-quantity',
        Delete = 'data-basket-line-delete',
        Price = 'data-basket-line-price',
        Saving = 'data-basket-line-saving',
        TotalPrice = 'data-basket-line-total-price',
        AssemblyCost = 'data-basket-line-assembly-cost',
        RemovalCost = 'data-basket-line-removal-cost',
        Id = 'data-basket-line-id',
        SizeId = 'data-basket-line-size-id'
    }

    export enum BasketLineGtm {
        GtmBasketLine = 'data-gtm-basket-line',
        GtmBasketLineId = 'data-gtm-basket-line-id',
        GtmBasketLineGroupSku = 'data-gtm-basket-line-group-sku',
        GtmBasketLineSku = 'data-gtm-basket-line-sku',
        GtmBasketLineName = 'data-gtm-basket-line-name',
        GtmBasketLinePrice = 'data-gtm-basket-line-price',
        GtmBasketLineItemPrice = 'data-gtm-basket-line-item-price',
        GtmBasketLineQuantity = 'data-gtm-basket-line-qty',
        GtmBasketLineBrand = 'data-gtm-basket-line-brand',
        GtmBasketLineSize = 'data-gtm-basket-line-size',
        GtmBasketLineCategory = 'data-gtm-basket-line-category',
        GtmBasketLineUrl = 'data-gtm-basket-line-url'
    }

    export enum BasketPrices {
        BasketPrices = 'data-basket-prices',
        TotalPrice = 'data-basket-prices-total-price',
        TotalSaving = 'data-basket-prices-total-saving'
    }

    export enum BasketDelivery {
        BasketDelivery = 'data-basket-delivery'
    }

    export enum MegaMenu {
        MegaMenuWrapper = 'data-mega-menu-wrapper',
        MegaMenu = 'data-mega-menu',
        MegaMenuHover = 'data-mega-menu-hover',
        MegaMenuPopOut = 'data-mega-menu-pop-out',
        MegaMenuContainer = 'data-mega-menu-container',
        MegaMenuHandHeldButton = 'data-mega-menu-hand-held-button'
    }
    export enum MegaMenuDesktop {
        MegaMenuDesktop = 'data-mega-menu-desktop',
    }

    export enum MegaMenuHandheld {
        MegaMenuHandheld = 'data-mega-menu-handheld',
    }

    export enum MegaMenuGtm {
        GtmMegaMenuName = 'data-gtm-mega-menu-name'
    }
    export enum Video {
        Video = 'data-video',
        VideoSource = 'data-video-source',
        VideoTitle = 'data-video-title',
        VideoPlaceholderSize = 'data-video-placeholder-size'
    }

    export enum Home {
        Home = 'data-home'
    }

    export enum Brand {
        Brand = 'data-brand'
    }

    export enum StaticPage {
        StaticPage = 'data-static'
    }

    export enum BasketSimple {
        BasketSimple = 'data-basket-simple'
    }

    export enum SearchBase {
        Search = 'data-search',
        Results = 'data-search-results',
        SearchBody = 'data-search-body'
    }

    export enum Search {
        Input = 'data-search-input',
        ClearButton = 'data-search-clear-button'
    }

    export enum SearchProducts {
        SearchProducts = 'data-search-products'
    }
    export enum SearchProduct {
        SearchProduct = 'data-search-product'
    }
    export enum SearchPages {
        SearchPages = 'data-search-pages'
    }
    export enum SearchPage {
        SearchPage = 'data-search-page'
    }

    export enum SearchGtm {
        GtmSearchItem = 'data-gtm-search-item',
        GtmSearchItemUrl = 'data-gtm-search-item-url',
        GtmSearchItemName = 'data-gtm-search-item-name',
    }

    export enum NotFoundSearch {
        NotFoundSearch = 'data-not-found-search',
        NotFoundSearchTerm = 'data-not-found-search-term'
    }

    export enum PageLoad {
        PageLoad = 'data-page-load'
    }

    export enum ContentItem {
        ContentItem = 'data-gtm-item',
        ContentItemUrl = 'data-gtm-item-url',
        ContentItemName = 'data-gtm-item-name',
        ContentItemId = 'data-gtm-item-id'
    }

    export enum Overlay {
        OverlayHidden = 'data-overlay-hidden'
    }

    export enum LiveChat {
        LiveChatLink = 'data-live-chat-link'
    }

    export enum CookieConsent {
        CookieConsent = 'data-cookie-consent',
        ConsentButton = 'data-cookie-consent-button'
    }


    export enum Notification {
        Notification = 'data-notification',
        Placeholder = 'data-notification-placeholder',
        Timeout = 'data-notification-timeout',
        Delay = 'data-notification-delay',
        Buttons = 'data-notification-buttons',
        Button = 'data-notification-button',
        ProgressBar = 'data-notification-progress-bar',
        ProgressBarTimer = 'data-notification-progress-bar-timer',
        CloseButton = 'data-notification-close-button',
        Display = 'data-notification-display',
        Message = 'data-notification-message',
        Role = 'data-notification-role',
        Agent = 'data-notification-agent',
        ButtonShow = 'data-notification-button-show'
    }

    export enum PopUpTimer {
        popUpTimer = 'data-notification',
        popUpTimerStart = 'data-notification-start',
        popUpTimerEnd = 'data-notification-end',
        popUpTimerProgressBar = 'data-notification-progress-bar',
        popUpTimerProgressBarTimer = 'data-notification-progress-bar-timer',
        popUpTimerClose = 'data-notification-close'
    }


}
