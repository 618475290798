import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { Carousel, ICarousel } from "./carousel";

export interface ICarouselTileLoad extends ICarousel {

}

export class CarouselTileLoad extends Carousel implements ICarouselTileLoad {

    constructor(public htmlElement: Element) {
        super(htmlElement);
        this.getTiles();
    }

    async getTiles() {
        try {
            let tileElements: NodeListOf<Element> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselTileLoad.Url));

            for (let currentElement = 0; currentElement < tileElements.length; currentElement += 1) {
                let tileElement: Element = tileElements[currentElement];
                let tileUrl: string = tileElement.getAttribute(AttributeEnums.CarouselTileLoad.Url);

                console.log('CarouselTileLoad: getTiles - url ' + tileUrl);
                await AjaxGetAndReplaceHtml(tileElement, tileUrl);
            }
            this.recallSetNextPreviousControls();
            this.setTileSize();
        } catch (error) {
            console.log('CarouselTileLoad: getTiles', error);
        }
    }

    dispose() {
        super.dispose();
    }
}